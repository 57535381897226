import React from "react";
import Footer from "./footer";
//npx serve -s build
import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";
import { getWebcall } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";
import { useState } from "react";

import { useEffect, useLayoutEffect } from "react";

import { gsap } from "gsap";
import CSSPlugin from "gsap/CSSPlugin";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, useGSAP, CSSPlugin);

function Home(props) {
  const C = CSSPlugin;
  const [decodedSVG, setDecodedSVG] = useState("");
  const [newDecodedSVG, setNewDecodedSVG] = useState("");
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [imlov, setImlov] = useState(null);
  var [intervalId, setIntervalId] = useState(null);
  var [intervalId2, setIntervalId2] = useState(null);

  const sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    props.onDataFromMenu(data);
  };

  const onConnectButton = async (event) => {
    const connect = await window.ethereum.request({ method: "eth_accounts" });

    if (connect.length === 0) {
      await getWebcall();
      const web3Instance = await detectAndSetWeb3();
      setWeb3(web3Instance);
      const accountsList = await web3Instance.eth.getAccounts();
      setAccounts(accountsList);
      onMount();
    }
  };

  const onMount = async () => {
    const web3Instance = await detectAndSetWeb3();
    const accountsList = await web3Instance.eth.getAccounts();
    setAccounts(accountsList);
    setWeb3(web3Instance);

    const imlovInstance = new web3Instance.eth.Contract(imlovABI, imlovaddress);
    setImlov(imlovInstance);

    const tokenURI = await imlovInstance.methods.tokenURI(0).call();
    const tokenURIStr = tokenURI.replace("data:application/json;utf8,", "");
    const encodedSVG = JSON.parse(tokenURIStr).image.replace(
      "data:image/svg+xml;base64,",
      ""
    );
    setDecodedSVG(String(atob(encodedSVG)));
  };

  useEffect(() => {
    sendDataToMenu();
    document.title = "Imperfect Lovers by Nathaniel Stern";
    onMount();

    const fetchOnChainValue = async () => {
      try {
        const tokenURI2 = await imlov.methods.tokenURI(0).call();
        const tokenURIStr2 = tokenURI2.replace(
          "data:application/json;utf8,",
          ""
        );
        const encodedSVG2 = JSON.parse(tokenURIStr2).image.replace(
          "data:image/svg+xml;base64,",
          ""
        );
        const newDecodedSVGtemp = String(atob(encodedSVG2));

        if (newDecodedSVGtemp !== decodedSVG) {
          setNewDecodedSVG(newDecodedSVGtemp);
          setDecodedSVG(newDecodedSVGtemp);
        }
      } catch (error) {
        console.error("Error fetching on-chain value:", error);
      }
    };

    const interval = setInterval(fetchOnChainValue, 300000); // Poll every 10 minutes
    setIntervalId(interval);

    const interval2 = setInterval(() => {
      if (!decodedSVG) {
        onMount();
        clearInterval(interval2);
      }
    }, 3000);
    setIntervalId2(interval2);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, [decodedSVG]);

  useEffect(() => {
    const preloadImages = () => {
      for (let i = 0; i <= 50; i++) {
        const img = new Image();
        img.src = `./images/imperfect-daylies/${i}.png`;
      }
    };

    preloadImages();
  }, []); // Empty dependency array ensures this runs only once

  useGSAP(() => {
    // gsap code here...

    const tl = gsap.timeline();
    tl.fromTo(
      ".slide-2",
      { opacity: 0, yPercent: 100 },
      { opacity: 1, yPercent: 0 }
    )
      .fromTo(".slide-3", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-4", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-5", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-5", { opacity: 1 }, { opacity: 1 })

      .fromTo(".slide-6", { opacity: 0 }, { opacity: 1 }, "slide-blank")
      .fromTo(".slide-blank", { opacity: 0 }, { opacity: 1 }, "slide-blank")

      .fromTo(".slide-blank", { opacity: 1 }, { opacity: 1 })

      .fromTo(
        ".slide-6",
        { opacity: 1, yPercent: 0 },
        { opacity: 0, yPercent: -150 },
        "sametime2"
      )
      .fromTo(
        ".slide-7",
        { opacity: 0, yPercent: 100 },
        { opacity: 1, yPercent: 0 },
        "sametime2"
      )

      .fromTo(".slide-7aa", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-7ab", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-7ba", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-7bb", { opacity: 0 }, { opacity: 1 })

      .fromTo(
        ".slide-7ab img ",
        { height: "70vh" },
        { height: "55vh" },
        "resizeSVG"
      )
      .fromTo(
        ".slide-7bb img ",
        { height: "70vh" },
        { height: "55vh" },
        "resizeSVG"
      )

      .fromTo(
        ".slide-8",
        { opacity: 0, yPercent: 100 },
        { opacity: 1, yPercent: 0 },
        "resizeSVG"
      )

      .fromTo(".slide-9", { opacity: 0 }, { opacity: 1 }, "eth")
      .fromTo(".slide-10-left", { opacity: 0 }, { opacity: 1 }, "eth")
      .fromTo(".slide-10-right", { opacity: 0 }, { opacity: 1 }, "eth")
      .fromTo(".slide-10", { opacity: 0 }, { opacity: 1 }, "longHand")

      .fromTo(".slide-11-left", { opacity: 0 }, { opacity: 1 }, "longHand")
      .fromTo(".slide-11-left-txt", { opacity: 0 }, { opacity: 1 }, "longHand")

      .fromTo(
        ".slide-11-left-img",
        { rotation: 0 },
        { rotation: -117 },
        "longHand"
      )

      .fromTo(".slide-11-right", { opacity: 0 }, { opacity: 1 }, "longHand")

      .fromTo(
        ".slide-11-right-img",
        { rotation: 0 },
        { rotation: -117 },
        "longHand"
      )

      .fromTo(".slide-11-right", { opacity: 1 }, { opacity: 1 })

      .fromTo(".slide-10", { opacity: 1 }, { opacity: 0 }, "eth-out")
      .fromTo(".slide-9", { opacity: 1 }, { opacity: 0 }, "eth-out")

      .fromTo(".slide-10-left", { opacity: 1 }, { opacity: 0 }, "eth-out")
      .fromTo(".slide-10-right", { opacity: 1 }, { opacity: 0 }, "eth-out")

      .fromTo(".slide-8", { opacity: 1 }, { opacity: 0 }, "eth-out")

      .fromTo(".slide-12", { opacity: 0 }, { opacity: 1 }, "text-out")

      .fromTo(".slide-11-left-txt", { opacity: 1 }, { opacity: 0 }, "text-out")

      .fromTo(".slide-13", { opacity: 0 }, { opacity: 1 }, "%-in")
      .fromTo(".slide-13-left", { opacity: 0 }, { opacity: 1 }, "%-in")
      .fromTo(".slide-13-right", { opacity: 0 }, { opacity: 1 }, "%-in")

      .fromTo(".slide-14", { opacity: 0 }, { opacity: 1 }, "gift")

      .fromTo(".slide-15-left", { opacity: 0 }, { opacity: 1 }, "gift")
      .fromTo(".slide-15-left-txt", { opacity: 0 }, { opacity: 1 }, "gift")

      .fromTo(".slide-15-right", { opacity: 0 }, { opacity: 1 }, "gift")

      .fromTo(
        ".slide-11-left-img",
        { rotation: -117 },
        { rotation: -97 },
        "gift"
      )

      .fromTo(
        ".slide-11-right-img",
        { rotation: -117 },
        { rotation: -82 },
        "gift"
      )

      .fromTo(".slide-15-right", { opacity: 1 }, { opacity: 1 })

      .fromTo(".slide-15-left-txt", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-12", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-13", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-13-left", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-13-right", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-14", { opacity: 1 }, { opacity: 0 }, "%-out")

      .fromTo(".slide-7ab img", { height: "55vh" }, { height: "70vh" }, "care")
      .fromTo(".slide-7bb img", { height: "55vh" }, { height: "70vh" }, "care")

      .fromTo(
        ".slide-15-left-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-left img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )

      .fromTo(
        ".slide-15-left-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-left img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )

      .fromTo(
        ".slide-11-left-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-left img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )

      .fromTo(
        ".slide-11-left-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-left img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )

      .fromTo(".slide-15a", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-15b", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-15c", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-15d", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-15c", { opacity: 1 }, { opacity: 0 }, "care-out")
      .fromTo(".slide-15d", { opacity: 1 }, { opacity: 0 }, "care-out")

      .fromTo(".slide-15e", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-15f", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-15g", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-15e", { opacity: 1 }, { opacity: 0 }, "time-pass")
      .fromTo(".slide-15f", { opacity: 1 }, { opacity: 0 }, "time-pass")
      .fromTo(".slide-15g", { opacity: 1 }, { opacity: 0 }, "time-pass")

      .fromTo(".slide-15b", { opacity: 1 }, { opacity: 0 })

      .fromTo(".slide-16", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-blank2", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-16", { opacity: 1 }, { opacity: 1 })

      .fromTo(".slide-blank3", { opacity: 0 }, { opacity: 1 });

    ScrollTrigger.create({
      animation: tl,
      trigger: ".container",
      start: "top 15%",
      end: "+=24000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });

    // gsap code here...
    const tl2 = gsap.timeline();

    for (let i = 0; i < 51; i++) {
      tl2.fromTo(".c2img" + i, { opacity: 0 }, { opacity: 1 });
    }

    tl2.fromTo(".c2-overlay", { opacity: 1 }, { opacity: 0 }, "portraits-out");

    tl2.fromTo(
      ".slide-blank4",
      { opacity: 0 },
      { opacity: 1 },
      "portraits-out"
    );

    const tl3 = gsap.timeline();
    tl3

    .fromTo(".container3b", { opacity: 0 }, { opacity: 1, duration: 0.01 })
      .fromTo(".container3a", { opacity: 0 }, { opacity: 1, duration: 0.01 })
      .fromTo(".c2-overlay", { opacity: 0 }, { opacity: 1, duration: 0.15 })
      .fromTo(".c2-overlay1", { opacity: 0 }, { opacity: 1, duration: 0.15 })
      .fromTo(".c2-overlay2", { opacity: 0 }, { opacity: 1, duration: 0.15 })

      .fromTo(".c2-overlay3", { opacity: 0 }, { opacity: 1, duration: 0.15 })
      .fromTo(".c2-overlay3", { opacity: 1 }, { opacity: 1, duration: 0.15 })
      .fromTo(".container3b", { opacity: 1 }, { opacity: 0, duration: 0.001 })
      .fromTo(".container3a", { opacity: 1 }, { opacity: 0, duration: 0.001 });

    const tl3a = gsap.timeline();
    tl3a.fromTo(".container3b", { left: 0 }, { left: 1300 });

    const tl4 = gsap.timeline();
    tl4.fromTo(".c4-overlay", { opacity: 0 }, { opacity: 1, duration: 0.01 })

 

    tl4.fromTo(
      ".c4-over",
      { marginLeft: 0 },
      { marginLeft: "100%", duration: 0.8 },
      "timeline"
    );

    tl4.fromTo(
      ".c4-over-text",
      { opacity: 0 },
      { opacity: 1, duration: 0.3 },
      "timeline"
    );

    tl4.fromTo(
      ".c4-slideblank",
      { opacity: 0 },
      { opacity: 1, duration: 0.05 }
    );

    tl4.fromTo(
      ".c4-slide1",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 },
      "rotatem"
    );

    tl4.fromTo(
      ".zerominutec",
      { rotate: -180 },
      { rotate: 0, duration: 0.4 },
      "rotatem"
    );

    tl4.fromTo(
      ".zerominutecc",
      { rotate: 180 },
      { rotate: 0, duration: 0.4 },
      "rotatem"
    );

    tl4.fromTo(".zerominutecc", { rotate: 0 }, { rotate: 0, duration: 0.02 });

    tl4.fromTo(".participate", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(
      ".participate-overlay",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 }
    );

    tl4.fromTo(
      ".participate-overlay2",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 }
    );

    tl4.fromTo(
      ".participate-overlay3",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 }
    );

    tl4.fromTo(".last-slide", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(".last-slide2", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(".last-slide3", { opacity: 0 }, { opacity: 1, duration: 0.25 });
    tl4.fromTo(".last-slide3", { opacity: 1 }, { opacity: 1, duration: 0.25 });


    tl4.fromTo(".final", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(".up", { opacity: 0 }, { opacity: 0.5, duration: 0.7 });

    tl4.fromTo(".imperfect", { opacity: 0 }, { opacity: 1, duration: 1 });

    ScrollTrigger.create({
      animation: tl2,
      trigger: ".container2",
      start: "top 15%",
      end: "+=6000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });

    ScrollTrigger.create({
      animation: tl3,
      trigger: ".container3",
      start: "top 15%",
      end: "+=6000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });

    ScrollTrigger.create({
      animation: tl3a,
      trigger: ".container3aaa",
      start: "top 15%",
      end: "+=8000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });

    ScrollTrigger.create({
      animation: tl4,
      trigger: ".container4",
      start: "top 15%",
      end: "+=16000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });
  });

  //do the push thing here and then use the tostring?

  return (
    <div>
      <Webconnection />

      <link rel="stylesheet" href="animations.css" />

      <div
        className="w3-right"
        style={{ width: "230px", marginRight: "5%", marginTop: "-5px" }}
      >
        {accounts != 0 && (
          <button
            type="button"
            disabled
            className="connect
      w3-button w3-light-grey w3-section"
          >
            connected: {accounts}
          </button>
        )}

        {accounts == 0 && (
          <button
            className="connect
      w3-button w3-light-grey w3-section"
            onClick={onConnectButton}
          >
            connect wallet
          </button>
        )}
      </div>

      <div style={{ marginLeft: "10%", marginTop: "80px", position: "sticky" }}>
        <h1>Imperfect Lovers</h1>
        <h5>
          a continuous networked performance <br />
          inaugurated February 23, 2024
          <br />
          by <a href="https://nathanielstern.art/"> Nathaniel Stern </a>
        </h5>
        <br />
      </div>

      <div style={{ textAlign: "right", marginRight: "6%" }}>
        <a href="#jump">skip intro scroll</a>
      </div>

      <div className="container">
        <div className="slide-1"></div>

        <div className="slide-2">Imperfect Lovers</div>
        <div className="slide-3">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            A conceptual performance piece, <br /> <br />
            <br />
          </div>
        </div>

        <div className="slide-4">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            A conceptual performance piece,
            <br />
            conceived and coded by artist and professor Nathaniel Stern,
            <br />
          </div>
        </div>

        <div className="slide-5">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            A conceptual performance piece,
            <br />
            conceived and coded by artist and professor Nathaniel Stern, <br />
            enacted as two interlocking, and nearly identical, smart contracts
            — 
          </div>
        </div>
        <div className="slide-blank"> </div>
        <div className="slide-6">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            <br />
            <div style={{ fontSize: "calc(2.8vw + 2.8vh)" }}>
              two lovers, linked forever in mutual care.
            </div>
          </div>
        </div>

        <div className="slide-7">
          <div className="slide-7a">
            <div className="slide-7aa">
              <div style={{ paddingLeft: "1vh" }}>Lover #0 </div>
            </div>
            <div className="slide-7ab">
              <img src="./images/lover-empty.svg" />
            </div>
          </div>

          <div className="slide-7b">
            <div className="slide-7ba">
              <div style={{ paddingRight: "1vh" }}>Lover #1 </div>
            </div>
            <div className="slide-7bb">
              <img src="./images/lover-empty.svg" />
            </div>
          </div>

          <div className="slide-8">
            At the start of the performance, the Lovers &#8212; presented as
            clocks &#8212;
          </div>

          <div className="slide-9">
            At the start of the performance, the Lovers &#8212; presented as
            clocks &#8212; <br />
            were both seeded with a balance of 0.65 ETH.
          </div>

          <div className="slide-10-left">
            <img src="./images/lover-eth.svg" />
          </div>

          <div className="slide-10-right">
            <img src="./images/lover-eth.svg" />
          </div>

          <div className="slide-10">
            At the start of the performance, the Lovers &#8212; presented as
            clocks &#8212; <br />
            were both seeded with a balance of 0.65 ETH. <br />
            <i>The long hand shows a Lover's current balance.</i>
          </div>

          <div className="slide-11-left">
            <div className="slide-11-left-txt">
              <img src="./images/lover-text.svg" />
            </div>

            <div className="slide-11-left-img">
              <img src="./images/lover-minute.svg" />
            </div>
          </div>

          <div className="slide-11-right">
            <div className="slide-11-left-txt">
              <img src="./images/lover-text.svg" />
            </div>
            <div className="slide-11-right-img">
              <img src="./images/lover-minute.svg" />
            </div>
          </div>

          <div className="slide-12">
            Once per day, the Lovers attempt to gift
          </div>

          <div className="slide-13">
            Once per day, the Lovers attempt to gift <br />a percentage from
            their balances to the other.
          </div>

          <div className="slide-13-left">
            <img src="./images/lover-percent.svg" />
          </div>

          <div className="slide-13-right">
            <img src="./images/lover-percent.svg" />
          </div>

          <div className="slide-14">
            Once per day, the Lovers attempt to gift <br />
            a percentage from their balances to the other.
            <br />
            <i> The short hand tells how much a Lover gifted that day.</i>
          </div>

          <div className="slide-15-left">
            <div className="slide-15-left-txt">
              <img src="./images/lover-hour-text.svg" />
            </div>

            <div className="slide-15-left-img" style={{ rotate: "-9deg" }}>
              <img src="./images/lover-hour.svg" />
            </div>
          </div>

          <div className="slide-15-right">
            <div className="slide-15-left-txt">
              <img src="./images/lover-hour-text1.svg" />
            </div>
            <div className="slide-15-right-img">
              <img src="./images/lover-hour.svg" style={{ rotate: "-30deg" }} />
            </div>
          </div>

          <div className="slide-15a"></div>

          <div className="slide-15b"></div>

          <div className="slide-15c">
            The left side of each clock shows the other Lover's balance and
            gift, <br />
          </div>

          <div className="slide-15d">
            The left side of each clock shows the other Lover's balance and
            gift, <br />
            making the two clocks <i>mirror images</i>.<br />
          </div>

          <div className="slide-15e">
            Each mirrored second hand (
            <div style={{ color: "rgb(220,0,0)", display: "inline" }}>red</div>)
            shows the real passing of time, <br />
            <br />
          </div>

          <div className="slide-15f">
            Each mirrored second hand (
            <div style={{ color: "rgb(220,0,0)", display: "inline" }}>red</div>)
            shows the real passing of time, <br />
            hinting at the Lovers' impossible promise of "forever"
            <br />
          </div>
          <div className="slide-15g">
            Each mirrored second hand (
            <div style={{ color: "rgb(220,0,0)", display: "inline" }}>red</div>)
            shows the real passing of time, <br />
            hinting at the Lovers' impossible promise of "forever"
            <br />
            and amplifying the true gift they give, time and time again.
          </div>

          <div className="slide-blank2"> </div>
          <div className="slide-16">a daily act of reciprocity & care.</div>

          <div className="slide-blank3"> </div>
        </div>
      </div>

      <div className="container2">
        <div className="container3">
          <div className="container3aaa">
            <div className="container3a"> </div>
            <div className="container3b"> </div>
            <div className="posAbsfull c2-overlay">
              <div className="posAbsfull c2-overlay1">
                As time passes, their love remains steadfast. <br />
              </div>

              <div className="posAbsfull c2-overlay2">
                <br /> Even while, with each transaction, <br />
              </div>
              <div className="posAbsfull c2-overlay3">
                <br /> <br />
                their balancing ebbs and flows.
              </div>
            </div>
          </div>
        </div>

        <div className="c2img0"></div>

        <div className="c2img1"></div>

        <div className="img2"></div>

        <div className="c2img3"></div>

        <div className="c2img4"></div>

        <div className="c2img5"></div>

        <div className="c2img6"></div>

        <div className="c2img7"></div>

        <div className="c2img8"></div>

        <div className="c2img9"></div>

        <div className="c2img9"></div>

        <div className="c2img10"></div>

        <div className="c2img11"></div>

        <div className="c2img12"></div>

        <div className="c2img13"></div>

        <div className="c2img14"></div>

        <div className="c2img15"></div>

        <div className="c2img16"></div>

        <div className="c2img17"></div>

        <div className="c2img18"></div>

        <div className="c2img19"></div>

        <div className="c2img20"></div>

        <div className="c2img21"></div>

        <div className="c2img22"></div>

        <div className="c2img23"></div>

        <div className="c2img24"></div>

        <div className="c2img25"></div>

        <div className="c2img26"></div>

        <div className="c2img27"></div>

        <div className="c2img28"></div>

        <div className="c2img29"></div>

        <div className="c2img30"></div>

        <div className="c2img31"></div>

        <div className="c2img32"></div>

        <div className="c2img33"></div>

        <div className="c2img34"></div>

        <div className="c2img35"></div>

        <div className="c2img36"></div>

        <div className="c2img37"></div>

        <div className="c2img38"></div>

        <div className="c2img39"></div>

        <div className="c2img40"></div>

        <div className="c2img41"></div>

        <div className="c2img42"></div>

        <div className="c2img43"></div>

        <div className="c2img44"></div>

        <div className="c2img45"></div>

        <div className="c2img46"></div>

        <div className="c2img47"></div>

        <div className="c2img48"></div>

        <div className="c2img49"></div>

        <div className="c2img50"></div>

        <div className="slide-blank4 posAbsfull"></div>
      </div>

      <div className="container4">
        <div className="posAbs2 c4-overlay">
          <div className="posAbs2 c4-img">
            <div className="posAbs2 c4-over"></div>

            <div className="posAbs2 c4-over-text">
              <div
                style={{
                  position: "relative",
                  width: "75%",
                  left: "20vw",
                  top: "3vh",
                  color: "rgb(180,0,0)",
                  fontSize: "calc(1.8vw + 1.8vh)",
                }}
              >
                Their balances slowly move toward zero.
              </div>
            </div>

            <div className="posAbs2  c4-slideblank"></div>

            <div className="posAbs2 c4-slide1">
              <div className="posAbs2">
                <img src="./images/lover-eth.svg" />
              </div>

              <div className="posAbs2 zerominutec">
                <img src="./images/lover-minute.svg" />
              </div>

              <div className="posAbs2 zerominutecc">
                <img src="./images/lover-minute.svg" />
              </div>

              <div
                style={{
                  position: "relative",
                  top: "2vh",
                  color: "rgb(180,0,0)",
                  zIndex: "2600",
                  fontSize: "calc(1.8vw + 1.8vh)",
                  height: "calc(18w + 10vh)",
                  backgroundColor: "rgba(255,255,255,.8)",
                }}
              >
                And yet, because of their (smart) contract, <br />
                they will continue trying to give
                <br />
                even when there's nothing left.
              </div>
            </div>
          </div>

          <div className="participate posAbs2">
            <div className="participate-overlay posAbs2">
              Online participants trigger the Lovers' <gr />
              daily giftings to one another.
              <br /> <br />
              <br />
              <div className="participate-overlay2 posAbs2">
                <br />
                <br /> <br />
                Gas fees are the only cost, <br />
                which are mostly refunded (from the Lovers' balances).
                <br /> <br />
              </div>
              <div className="participate-overlay3 posAbs2">
                <br /> <br />
                <br />
                <br /> <br />
                <br />
                With participation, they receive a daily display &#8212; <br />
                a Portrait of the Lovers, a snapshot of their current state.
              </div>
            </div>
          </div>

          <div className="last-slide">
            <div
              className="last-slide1"
              style={{
                marginTop: "10vh",
              }}
            >
              All daily and historical Lover data is fully <br />
              on-chain and permanently available,
            </div>

            <div className="last-slide2">
              so that other creatives might make their own <br />
              
            </div>

            <div className="last-slide3">interfaces for the Lovers.</div>
          </div>

          <div className="final posAbs2">
            <div
              className="imperfect posAbs2"
              style={{
                color: "white",
                zIndex: "4700",
                fontSize: "calc(5vw + 5vh)",
              }}
            >
              Imperfect Lovers
            </div>
            <div className="up">
              <div style={{ marginTop: "19vh" }}>
                What does it look like to <br />
                give beyond your capacity?
              </div>
            </div>

            <div style={{ marginTop: "19vh" }}>
              What does it look like to <br />
              give beyond your capacity?
            </div>
          </div>
        </div>
      </div>

      <div id="jump" style={{ position: "relative", top: "-140vh" }}></div>

      <div
        className="w3-content"
        style={{ position: "relative", top: "-140vh", zIndex: "5000" }}
      >
        <div className="w3-padding-32" id="about">
          <div className="w3-padding-large">

          <p>
              <i>Imperfect Lovers</i> is dedicated to Mary Catherine Stern. <br />
              It was released on February 23, 2024 — Nathaniel and Mary Catherine's sixth wedding anniversary. </p>
            <p
              style={{
                fontSize: "calc(1.6vw + 1.6vh)",
                textAlign: "center",
                color: "#444444",
                fontFamily: '"Times New Roman", Georgia, Serif',
              }}
            >
              Watch and listen to Nathaniel Stern <br />
              talk through <i>Imperfect Lovers</i> (4 min video)
              <br /> <br />
              <iframe
                style={{ aspectRatio: "16 / 9", width: "100%", height: "auto" }}
                src="https://www.youtube.com/embed/_tPZ4QhxBJo?si=OQHCXCSjWS5cRzIQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </p>
           
            <p>
             <b> Read <a href="./artist-statement">Nathaniel Stern's artist statement for <i>Imperfect Lovers</i></a></b>
           
            </p>


            

            

            <p>&ndash; &ndash; &ndash;</p>
            

            <p>
              <i>Imperfect Lovers</i> is funded in part by the{" "}
              <a href="https://www.refractionfestival.com/">Refraction</a>{" "}
              Community Arts Fund (partial minting costs and seed money for the
              Lovers) and a Dean&apos;s award from Peck School of the Arts,
              University of Wisconsin-Milwaukee (which paid for some technical
              consulting/support from{" "}
              <a href="https://steviep.xyz/">Steve Pikelny</a>).{" "}
              <a href="https://kingduane.com/">Duane King</a> assisted with the
              SVG clock design, and{" "}
              <a href="https://linktr.ee/haiverart">Haiver</a> helped develop
              the "scrollytelling" storyboard.
            </p>

            <div style={{ textAlign: "right" }}>
              <a href="#top">back to top</a>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "-140vh" }}>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
